body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-input {
  border-radius: 15px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  height: 35px;
  box-shadow: 0 0 2px #0074d9;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #0c8af8;
  box-shadow: 0 0 10px 2px #0074d9;
}